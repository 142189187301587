<template>
  <div class="box-page-new">
    <NavbarOne
      class="d-md-none d-lg-none"
      :showNavbar="showNavbar"
      :showCart="showCart"
      :showCartDesktop="showCartDesktop"
      :showSearch="showSearch"
      :showLogo="showLogo"
    >
    </NavbarOne>
    <div>
      <v-row class="content-table">
        <v-col cols="12" md="12">
          <div class="d-flex justify-content align-center mb-2 pb-3">
            <v-row>
              <v-col
                cols="12"
                md="6"
                lg="6"
                class="d-flex align-center justify-start"
              >
                <localized-link to="/company/employee/master">
                  <v-icon class="ml-4">mdi-arrow-left</v-icon>
                </localized-link>
                <h4 class="ml-2">
                    {{ $t("page.employee.detail_employee") }}
                </h4>
              </v-col>
              <v-col
                cols="12"
                md="6"
                lg="6"
                class="d-flex align-center justify-lg-end"
              >
                <v-btn
                  color="primary"
                  class="button-edit-employee fontSet"
                  @click="editItem(employeeDetailData)"
                >
                  {{ $t("edit") }}
                </v-btn>
                <v-btn
                  class="button-delete-employee fontSet"
                  @click="deleteItem(employeeDetailData)"
                  color="white"
                >
                  {{ $t("delete") }}
                </v-btn>
              </v-col>
            </v-row>
          </div>

          <v-card>
            <!-- <v-divider></v-divider> -->
            <v-card-text>
              <v-row no-gutters class="pl-lg-5 pr-lg-5 pt-2">
                <v-col cols="12" md="12">
                  <h4 class="title-information">
                    {{ $t("page.employee.employee_information") }}
                  </h4>
                </v-col>
              </v-row>
              <v-row no-gutters class="pl-lg-5 pr-lg-5 pt-5 pb-5">
                <v-col cols="12">
                  <card rounded shadow>
                    <template v-slot:body>
                      <v-row>
                        <v-col cols="6" class="d-none d-lg-block pt-0">
                          <div class="pl-4">
                            <v-row no-gutters class="list-data">
                              <v-col class="text-new-title">
                                {{ employeeDetailData.name }}
                              </v-col>
                            </v-row>
                          </div>
                          <div class="pl-4">
                            <v-row no-gutters class="list-data">
                              <v-col class="text-new-value">
                                {{ employeeDetailData.address }}
                              </v-col>
                            </v-row>
                          </div>
                          <div class="pl-4">
                            <v-row no-gutters class="list-data">
                              <v-col
                                v-if="
                                  employeeDetailData.city != null &&
                                  employeeDetailData.district != null
                                "
                                class="text-new-value"
                              >
                                {{
                                  employeeDetailData.city +
                                  ", " +
                                  employeeDetailData.district
                                }}
                              </v-col>
                              <v-col
                                v-if="
                                  employeeDetailData.city != null &&
                                  employeeDetailData.city == null
                                "
                                class="text-new-value"
                              >
                                {{ employeeDetailData.city + ", -" }}
                              </v-col>
                              <v-col
                                v-if="
                                  employeeDetailData.city == null &&
                                  employeeDetailData.city != null
                                "
                                class="text-new-value"
                              >
                                {{ "- ," + employeeDetailData.district }}
                              </v-col>
                              <v-col v-else class="text-new-value"> - </v-col>
                            </v-row>
                          </div>
                          <div class="pl-4">
                            <v-row no-gutters class="list-data">
                              <v-col
                                v-if="
                                  employeeDetailData.province != null &&
                                  employeeDetailData.postal_code != null &&
                                  employeeDetailData.country_name != null
                                "
                                class="text-new-value"
                              >
                                {{
                                  employeeDetailData.province +
                                  " " +
                                  employeeDetailData.postal_code +
                                  ", " +
                                  employeeDetailData.country_name
                                }}
                              </v-col>
                              <v-col
                                v-if="
                                  employeeDetailData.province != null &&
                                  employeeDetailData.postal_code != null &&
                                  employeeDetailData.country_name == null
                                "
                                class="text-new-value"
                              >
                                {{
                                  employeeDetailData.province +
                                  " " +
                                  employeeDetailData.postal_code +
                                  ", -"
                                }}
                              </v-col>
                              <v-col
                                v-if="
                                  employeeDetailData.province != null &&
                                  employeeDetailData.postal_code == null &&
                                  employeeDetailData.country_name != null
                                "
                                class="text-new-value"
                              >
                                {{
                                  employeeDetailData.province +
                                  " " +
                                  "- , " +
                                  employeeDetailData.country_name
                                }}
                              </v-col>
                              <v-col
                                v-if="
                                  employeeDetailData.province == null &&
                                  employeeDetailData.postal_code != null &&
                                  employeeDetailData.country_name != null
                                "
                                class="text-new-value"
                              >
                                {{
                                  "- " +
                                  employeeDetailData.postal_code +
                                  ", " +
                                  employeeDetailData.country_name
                                }}
                              </v-col>
                              <v-col v-else class="text-new-value"> - </v-col>
                            </v-row>
                          </div>
                        </v-col>
                        <v-col cols="6" class="d-none d-lg-block pt-0">
                          <div class="pr-4">
                            <v-row no-gutters class="list-data">
                              <v-col class="text-title">
                                {{ $t("field.mobile_phone") }}
                              </v-col>
                              <v-col class="text-value">
                                {{ employeeDetailData.phone_number }}
                              </v-col>
                            </v-row>
                          </div>
                          <div class="pr-4">
                            <v-row no-gutters class="list-data">
                              <v-col class="text-title">
                                {{ $t("field.email") }}
                              </v-col>
                              <v-col class="text-value">
                                {{ employeeDetailData.email }}
                              </v-col>
                            </v-row>
                          </div>
                          <div class="pr-4">
                            <v-row no-gutters class="list-data">
                              <v-col class="text-title">
                                {{ $t("field.place_of_birth") }}
                              </v-col>
                              <v-col class="text-value">
                                {{ employeeDetailData.place_of_birth }}
                              </v-col>
                            </v-row>
                          </div>
                          <div class="pr-4">
                            <v-row no-gutters class="list-data">
                              <v-col class="text-title">
                                {{ $t("field.dobirth") }}
                              </v-col>
                              <v-col class="text-value">
                                {{
                                  formatDate(employeeDetailData.date_of_birth)
                                }}
                              </v-col>
                            </v-row>
                          </div>
                        </v-col>
                        <v-col class="d-block d-lg-none pt-0" cols="12">
                          <div>
                            <v-row no-gutters class="list-data">
                              <v-col class="text-new-value">
                                {{ employeeDetailData.name }}
                              </v-col>
                            </v-row>
                          </div>
                          <div>
                            <v-row no-gutters class="list-data">
                              <v-col class="text-new-value">
                                {{ employeeDetailData.address }}
                              </v-col>
                            </v-row>
                          </div>
                          <div>
                            <v-row no-gutters class="list-data">
                              <v-col
                                v-if="
                                  employeeDetailData.city != null &&
                                  employeeDetailData.district != null
                                "
                                class="text-new-value"
                              >
                                {{
                                  employeeDetailData.city +
                                  ", " +
                                  employeeDetailData.district
                                }}
                              </v-col>
                              <v-col
                                v-if="
                                  employeeDetailData.city != null &&
                                  employeeDetailData.city == null
                                "
                                class="text-new-value"
                              >
                                {{ employeeDetailData.city + ", -" }}
                              </v-col>
                              <v-col
                                v-if="
                                  employeeDetailData.city == null &&
                                  employeeDetailData.city != null
                                "
                                class="text-new-value"
                              >
                                {{ "- ," + employeeDetailData.district }}
                              </v-col>
                              <v-col v-else class="text-new-value"> - </v-col>
                            </v-row>
                          </div>
                          <div>
                            <v-row no-gutters class="list-data">
                              <v-col
                                v-if="
                                  employeeDetailData.province != null &&
                                  employeeDetailData.postal_code != null &&
                                  employeeDetailData.country_name != null
                                "
                                class="text-new-value"
                              >
                                {{
                                  employeeDetailData.province +
                                  " " +
                                  employeeDetailData.postal_code +
                                  ", " +
                                  employeeDetailData.country_name
                                }}
                              </v-col>
                              <v-col
                                v-if="
                                  employeeDetailData.province != null &&
                                  employeeDetailData.postal_code != null &&
                                  employeeDetailData.country_name == null
                                "
                                class="text-new-value"
                              >
                                {{
                                  employeeDetailData.province +
                                  " " +
                                  employeeDetailData.postal_code +
                                  ", -"
                                }}
                              </v-col>
                              <v-col
                                v-if="
                                  employeeDetailData.province != null &&
                                  employeeDetailData.postal_code == null &&
                                  employeeDetailData.country_name != null
                                "
                                class="text-new-value"
                              >
                                {{
                                  employeeDetailData.province +
                                  " " +
                                  "- , " +
                                  employeeDetailData.country_name
                                }}
                              </v-col>
                              <v-col
                                v-if="
                                  employeeDetailData.province == null &&
                                  employeeDetailData.postal_code != null &&
                                  employeeDetailData.country_name != null
                                "
                                class="text-new-value"
                              >
                                {{
                                  "- " +
                                  employeeDetailData.postal_code +
                                  ", " +
                                  employeeDetailData.country_name
                                }}
                              </v-col>
                              <v-col v-else class="text-new-value"> - </v-col>
                            </v-row>
                          </div>
                          <div>
                            <v-row no-gutters class="list-data">
                              <v-col class="text-title">
                                {{ $t("field.mobile_phone") }}
                              </v-col>
                              <v-col class="text-value">
                                {{ employeeDetailData.phone_number }}
                              </v-col>
                            </v-row>
                          </div>
                          <div>
                            <v-row no-gutters class="list-data">
                              <v-col class="text-title">
                                {{ $t("field.email") }}
                              </v-col>
                              <v-col class="text-value">
                                {{ employeeDetailData.email }}
                              </v-col>
                            </v-row>
                          </div>
                          <div>
                            <v-row no-gutters class="list-data">
                              <v-col class="text-title">
                                {{ $t("field.place_of_birth") }}
                              </v-col>
                              <v-col class="text-value">
                                {{ employeeDetailData.place_of_birth }}
                              </v-col>
                            </v-row>
                          </div>
                          <div>
                            <v-row no-gutters class="list-data">
                              <v-col class="text-title">
                                {{ $t("field.dobirth") }}
                              </v-col>
                              <v-col class="text-value">
                                {{
                                  formatDate(employeeDetailData.date_of_birth)
                                }}
                              </v-col>
                            </v-row>
                          </div>
                        </v-col>
                      </v-row>
                    </template>
                  </card>
                </v-col>
              </v-row>

              <v-row no-gutters class="pl-lg-5 pr-lg-5 pt-5 pb-5">
                <v-col cols="12">
                  <card rounded shadow>
                    <template v-slot:body>
                      <v-row
                        v-for="(
                          getIdentity, index
                        ) of employeeDetailData.identity"
                        :key="index"
                      >
                        <!-- <div > -->
                        <v-col cols="6" class="d-none d-lg-block pb-0 pt-0">
                          <div class="pl-4">
                            <v-row no-gutters class="list-data">
                              <v-col class="text-title">
                                {{ $t("field.identity_type") }}
                              </v-col>
                              <v-col class="text-value">
                                {{ getIdentity.identity_type }}
                              </v-col>
                            </v-row>
                          </div>
                        </v-col>
                        <v-col cols="6" class="d-none d-lg-block pb-0 pt-0">
                          <div class="pr-4">
                            <v-row no-gutters class="list-data">
                              <v-col class="text-title">
                                {{ $t("field.id_number") }}
                              </v-col>
                              <v-col class="text-value">
                                {{ getIdentity.identity_number }}
                              </v-col>
                            </v-row>
                          </div>
                        </v-col>
                        <!-- </div> -->
                      </v-row>
                      <v-row>
                        <v-col cols="6" class="d-none d-lg-block pt-0">
                          <div class="pl-4">
                            <v-row no-gutters class="list-data">
                              <v-col class="text-title">
                                {{ $t("field.employee_number") }}
                              </v-col>
                              <v-col class="text-value">
                                {{ employeeDetailData.employee_number }}
                              </v-col>
                            </v-row>
                          </div>
                          <div class="pl-4">
                            <v-row no-gutters class="list-data">
                              <v-col class="text-title">
                                {{ $t("field.department") }}
                              </v-col>
                              <v-col class="text-value">
                                {{ employeeDetailData.department }}
                              </v-col>
                            </v-row>
                          </div>
                          <div class="pl-4">
                            <v-row no-gutters class="list-data">
                              <v-col class="text-title">
                                {{ $t("field.jobpost") }}
                              </v-col>
                              <v-col class="text-value">
                                {{ employeeDetailData.job_position }}
                              </v-col>
                            </v-row>
                          </div>
                          <div class="pl-4">
                            <v-row no-gutters class="list-data">
                              <v-col class="text-title">
                                {{ $t("field.level") }}
                              </v-col>
                              <v-col class="text-value">
                                {{ employeeDetailData.employee_level }}
                              </v-col>
                            </v-row>
                          </div>
                          <div class="pl-4">
                            <v-row no-gutters class="list-data">
                              <v-col class="text-title">
                                {{ $t("field.citizenship") }}
                              </v-col>
                              <v-col class="text-value">
                                {{ employeeDetailData.citizenship_name }}
                              </v-col>
                            </v-row>
                          </div>
                        </v-col>
                        <v-col cols="6" class="d-none d-lg-block pt-0">
                          <div class="pr-4">
                            <v-row no-gutters class="list-data">
                              <v-col class="text-title">
                                {{ $t("field.marital_status") }}
                              </v-col>
                              <v-col class="text-value">
                                {{ employeeDetailData.marital_status }}
                              </v-col>
                            </v-row>
                          </div>
                          <div class="pr-4">
                            <v-row no-gutters class="list-data">
                              <v-col class="text-title">
                                {{ $t("field.gender") }}
                              </v-col>
                              <v-col class="text-value">
                                {{ employeeDetailData.gender }}
                              </v-col>
                            </v-row>
                          </div>
                          <div class="pr-4">
                            <v-row no-gutters class="list-data">
                              <v-col class="text-title">
                                {{ $t("field.race") }}
                              </v-col>
                              <v-col class="text-value">
                                {{ employeeDetailData.ethnicity }}
                              </v-col>
                            </v-row>
                          </div>
                          <div class="pr-4">
                            <v-row no-gutters class="list-data">
                              <v-col class="text-title">
                                {{ $t("field.blood_type") }}
                              </v-col>
                              <v-col class="text-value">
                                {{ employeeDetailData.blood_type }}
                              </v-col>
                            </v-row>
                          </div>
                          <div class="pr-4">
                            <v-row no-gutters class="list-data">
                              <v-col class="text-title">
                                {{ $t("field.religion") }}
                              </v-col>
                              <v-col class="text-value">
                                {{ employeeDetailData.religion }}
                              </v-col>
                            </v-row>
                          </div>
                        </v-col>
                        <v-col class="d-block d-lg-none pt-0" cols="12">
                          <div
                            v-for="(
                              getIdentityBu, indexBu
                            ) of employeeDetailData.identity"
                            :key="indexBu"
                          >
                            <v-row no-gutters class="list-data">
                              <v-col class="text-title">
                                {{ $t("field.identity_type") }}
                              </v-col>
                              <v-col class="text-value">
                                {{ getIdentityBu.identity_type }}
                              </v-col>
                            </v-row>

                            <v-row no-gutters class="list-data">
                              <v-col class="text-title">
                                {{ $t("field.id_number") }}
                              </v-col>
                              <v-col class="text-value">
                                {{ getIdentityBu.identity_number }}
                              </v-col>
                            </v-row>
                          </div>

                          <div>
                            <v-row no-gutters class="list-data">
                              <v-col class="text-title">
                                {{ $t("field.employee_number") }}
                              </v-col>
                              <v-col class="text-value">
                                {{ employeeDetailData.employee_number }}
                              </v-col>
                            </v-row>
                          </div>
                          <div>
                            <v-row no-gutters class="list-data">
                              <v-col class="text-title">
                                {{ $t("field.department") }}
                              </v-col>
                              <v-col class="text-value">
                                {{ employeeDetailData.department }}
                              </v-col>
                            </v-row>
                          </div>
                          <div>
                            <v-row no-gutters class="list-data">
                              <v-col class="text-title">
                                {{ $t("field.jobpost") }}
                              </v-col>
                              <v-col class="text-value">
                                {{ employeeDetailData.job_position }}
                              </v-col>
                            </v-row>
                          </div>
                          <div>
                            <v-row no-gutters class="list-data">
                              <v-col class="text-title">
                                {{ $t("field.level") }}
                              </v-col>
                              <v-col class="text-value">
                                {{ employeeDetailData.employee_level }}
                              </v-col>
                            </v-row>
                          </div>
                          <div>
                            <v-row no-gutters class="list-data">
                              <v-col class="text-title">
                                {{ $t("field.citizenship") }}
                              </v-col>
                              <v-col class="text-value">
                                {{ employeeDetailData.citizenship_name }}
                              </v-col>
                            </v-row>
                          </div>
                          <div>
                            <v-row no-gutters class="list-data">
                              <v-col class="text-title">
                                {{ $t("field.marital_status") }}
                              </v-col>
                              <v-col class="text-value">
                                {{ employeeDetailData.marital_status }}
                              </v-col>
                            </v-row>
                          </div>
                          <div>
                            <v-row no-gutters class="list-data">
                              <v-col class="text-title">
                                {{ $t("field.gender") }}
                              </v-col>
                              <v-col class="text-value">
                                {{ employeeDetailData.gender }}
                              </v-col>
                            </v-row>
                          </div>
                          <div>
                            <v-row no-gutters class="list-data">
                              <v-col class="text-title">
                                {{ $t("field.race") }}
                              </v-col>
                              <v-col class="text-value">
                                {{ employeeDetailData.ethnicity }}
                              </v-col>
                            </v-row>
                          </div>
                          <div>
                            <v-row no-gutters class="list-data">
                              <v-col class="text-title">
                                {{ $t("field.blood_type") }}
                              </v-col>
                              <v-col class="text-value">
                                {{ employeeDetailData.blood_type }}
                              </v-col>
                            </v-row>
                          </div>
                          <div>
                            <v-row no-gutters class="list-data">
                              <v-col class="text-title">
                                {{ $t("field.religion") }}
                              </v-col>
                              <v-col class="text-value">
                                {{ employeeDetailData.religion }}
                              </v-col>
                            </v-row>
                          </div>
                        </v-col>
                      </v-row>
                    </template>
                  </card>
                </v-col>
              </v-row>
              <v-row no-gutters class="pl-lg-5 pr-lg-5 pt-5 pb-5">
                <v-col cols="12">
                  <card rounded shadow>
                    <template v-slot:body>
                      <v-row class="table-lists">
                        <v-col cols="12" md="12">
                          <div class="table-responsive">
                            <v-simple-table
                              class="elevation-1 table-employee-company"
                            >
                              <template v-slot:default>
                                <thead class="no-pad">
                                  <tr class="grey-color head-vir2">
                                    <th class="text-center">
                                      {{
                                        $t("page.employee.insurance_company")
                                      }}
                                    </th>
                                    <th class="text-center">
                                      {{ $t("field.insurance_number") }}
                                    </th>
                                    <th class="text-center">
                                      {{ $t("field.insurance_expired_at") }}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <template
                                    v-if="employeeDetailData.insurance != null"
                                  >
                                    <tr
                                      v-for="(
                                        item, index
                                      ) in employeeDetailData.insurance"
                                      :key="index"
                                    >
                                      <td
                                        valign="top"
                                        class="pt-2 sticky-popup-oc1"
                                      >
                                        {{ item.company_name }}
                                      </td>
                                      <td valign="top" class="pt-2">
                                        {{ item.insurance_number }}
                                      </td>
                                      <td valign="top" class="pt-2">
                                        {{ formatDate(item.expire_date) }}
                                      </td>
                                    </tr>
                                  </template>
                                  <tr v-else>
                                    <td colspan="5" class="text-center">
                                      {{ $t("no_data") }}
                                    </td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </div>
                        </v-col>
                      </v-row>
                    </template>
                  </card>
                </v-col>
              </v-row>
              <v-row no-gutters class="pl-lg-5 pr-lg-5 pt-5 pb-5">
                <v-col cols="12">
                  <card rounded shadow>
                    <template v-slot:body>
                      <v-row class="table-lists">
                        <v-col cols="12" md="12">
                          <div class="payment-info">
                            <v-data-table
                              :headers="headersLastMedical"
                              :items="employeeList"
                              class="table-employee-company"
                              :mobile-breakpoint="0"
                              hide-default-footer
                              :loading="myloadingvariablePopup"
                              :items-per-page="-1"
                              :custom-sort="customSort"
                            >
                              <template v-slot:top>
                                <v-row
                                  class="d-flex flex align-center justify-space-between px-0 py-0"
                                >
                                  <v-col cols="12" md="8">
                                    <v-toolbar-title
                                      class="text-title title-information pl-2"
                                      >{{ $t("page.profile.medical_record") }}
                                    </v-toolbar-title>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    md="4"
                                    class="d-flex justify-end align-center pr-0"
                                  >
                                    <v-text-field
                                      class="searchField fontSet pl-3 pr-3"
                                      outlined
                                      dense
                                      hide-details
                                      v-model="searchPopup"
                                      append-icon="mdi-magnify"
                                      :label="$t('search') + ' ...'"
                                    ></v-text-field>

                                    <div>
                                      <v-select
                                        class="footerHide"
                                        v-model="perPagePopup"
                                        :items="itemShowingPopup"
                                      ></v-select>
                                    </div>
                                  </v-col>
                                </v-row>
                              </template>
                              <template v-slot:item="{ item }">
                                <tr class="hd-mr-sticky">
                                  <td valign="top" class="pt-2 sticky-cmr1">
                                    {{
                                      item.registration_number !== null
                                        ? item.registration_number
                                        : "-"
                                    }}
                                  </td>
                                  <td valign="top" class="pt-2 sticky-cmr2">
                                    {{
                                      item.booking_date !== null
                                        ? formatDate(item.booking_date)
                                        : "-"
                                    }}
                                  </td>
                                  <td valign="top" class="pt-2 tb-wide">
                                    <template v-if="item.order_number !== null">
                                      <template v-if="item.complete == true">
                                        <a
                                          href="javascript:void(0);"
                                          @click="openDialogOrder(item)"
                                          >{{ item.order_number }}</a
                                        >
                                      </template>
                                      <template v-else>
                                        {{ item.order_number }}
                                      </template>
                                    </template>
                                    <template v-else> - </template>
                                  </td>
                                  <td valign="top" class="pt-2">
                                    {{
                                      item.booking_code !== null
                                        ? item.booking_code
                                        : "-"
                                    }}
                                  </td>
                                  <td valign="top" class="pt-2">
                                    {{
                                      item.name !== null
                                        ? item.name.toUpperCase()
                                        : "-"
                                    }}
                                  </td>
                                </tr>
                              </template>

                              <template v-slot:footer>
                                <v-row
                                  class="d-flex flex align-center justify-space-between footer-payment"
                                >
                                  <v-col cols="12" md="8">
                                    <p class="footer-showing">
                                      showing {{ itemShowStartPopup }} to
                                      {{ itemShowStopPopup }} of
                                      {{ itemsTotalPopup }} entries
                                    </p>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    md="4"
                                    class="d-flex justify-end"
                                  >
                                    <custom-pagination
                                      @changePage="(val) => (pagePopup = val)"
                                      :totalPage="itemLengthPopup"
                                      :activePage="pagePopup"
                                    />
                                  </v-col>
                                </v-row>
                              </template>
                            </v-data-table>

                            <DialogOrderDetail
                              v-if="dialog_order"
                              :item="dataDialogOrder"
                              :tampilkan="dialog_order"
                              @toggleDialog="
                                (is_show) => toggleDialogOrder(is_show)
                              "
                            />
                          </div>
                        </v-col>
                      </v-row>
                    </template>
                  </card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog v-model="dialogDelete" max-width="290">
              <v-card>
                <v-card-title class="text-h5">
                  {{ $t("confirmation_dialog") }}
                </v-card-title>
                <v-card-text>
                  {{ $t("page.profile.u_sure_want_delete") }}
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="closeDelete">{{ $t("cancel") }}</v-btn>
                  <v-btn color="#00a4b3" text @click="deleteItemConfirm">{{
                    $t("ok")
                  }}</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
    </div>
  </div>
</template>

<script>
// import TabContent from "@/components/v2/tabs/index.vue";
import API from "@/service/api_service.js";
import Auth from "@/service/auth_service";
import NavbarOne from "@/components/v2/topbar/navbar_one.vue";
import moment from "moment";
import CustomPagination from "@/components/v3/pagination.vue";
import Card from "@/components/v2/card/index.vue";
import DialogOrderDetail from "@/components/v3/results/index.vue";

export default {
  components: { NavbarOne, Card, CustomPagination, DialogOrderDetail },
  data: () => ({
    pagePopup: 1,
    options: null,
    itemsTotalPopup: 0,
    itemLengthPopup: 1,
    itemShowStartPopup: 0,
    itemShowStopPopup: 0,
    itemShowingPopup: [
      { value: 10, text: "10" },
      { value: 15, text: "15" },
      { value: 25, text: "25" },
    ],
    perPagePopup: 10,
    myloadingvariablePopup: true,
    showNavbar: true,
    showCart: false,
    showCartDesktop: false,
    showSearch: false,
    showLogo: true,
    searchPopup: "",
    dialogDelete: false,
    formHasError: false,
    headersEmployeeDetail: [],
    headersLastMedical: [],
    employeeList: [],
    datas: [],
    editedIndex: -1,
    employee_number: null,
    identity_type: null,
    name: null,
    department: null,
    identity_number: null,
    phone_number: null,
    email: null,
    date_of_birth: null,
    insurance_number: null,
    insurance_expired_at: null,
    employeeDetailData: [],
    errorMessages: "",
    defaultItem: {
      employee_number: "",
      identity_type: "",
      name: "",
      department: "",
      identity_number: "",
      phone_number: "",
      email: "",
      date_of_birth: "",
      departagement: "",
      insurance_number: "",
      insurance_expired_at: "",
    },
    selectData: [],
    dialog_order: false,
    dataDialogOrder: {
      order_id: null,
      list: [
        {
          document_information: {
            number_of_report: null,
            medical_record_number: null,
            datetime_observation: null,
            gender: null,
            plant: null,
            primary_doctor: null,
            lab_chief: null,
          },
          customers_information: {
            patient_name: null,
            address: null,
            city: null,
            province: null,
            postal_code: null,
            country: null,
            dob: null,
            patient_age: null,
          },
          product: {
            product_id: null,
            product_code: null,
            product_name: null,
          },
        },
      ],
    },
    idTemp: "",
  }),
  created() {
    this.headersEmployeeDetail = [
      {
        text: this.$t("page.employee.insurance_company"),
        align: "center",
        value: "company_name",
        class: "center",
      },
      {
        text: this.$t("field.insurance_number"),
        value: "insurance_number",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("field.insurance_expired_at"),
        value: "expire_date",
        align: "center",
        sortable: false,
      },
    ];

    this.headersLastMedical = [
      {
        text: this.$t("page.profile.registration_number"),
        value: "registration_number",
        align: "center",
        class: "sticky-hmr1",
      },
      {
        text: this.$t("page.profile.booking_date"),
        value: "booking_date",
        align: "center",
        class: "sticky-hmr2",
        sortable: true,
      },
      {
        text: this.$t("page.profile.order_no"),
        value: "order_number",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("page.profile.booking_code"),
        value: "booking_code",
        align: "center",
      },
      {
        text: this.$t("field.package"),
        value: "name",
        sortable: false,
        align: "center",
      },
    ];
  },
  async mounted() {
    this.getInitialEmployee(this.$route.params.id);
    this.getInitialRevenuePopup(this.$route.params.id);
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    pagePopup: function () {
      this.getInitialRevenuePopup(this.idTemp);
    },
    perPagePopup: function () {
      if (this.pagePopup != 1) {
        this.pagePopup = 1;
      } else {
        this.getInitialRevenuePopup(this.idTemp);
      }
    },
    searchPopup: function () {
      this.pagePopup = 1;
      this.getInitialRevenuePopup(this.idTemp);
    },
  },
  methods: {
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
          if (index[0]=='booking_date') {
            if (!isDesc[0]) {
                return new Date(b[index]) - new Date(a[index]);
            } else {
                return new Date(a[index]) - new Date(b[index]);
            }
          }
          else {
            if(typeof a[index] !== 'undefined'){
              if (!isDesc[0]) {
                 return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
              }
              else {
                  return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
              }
            }
          }
      });
      return items;
    },
    formatDate(date) {
      if (!date) return null;
      return moment(date)
        .locale(this.$i18n.locale)
        .format("DD-MMM-YYYY")
        .toUpperCase();
    },
    toast(status, messages) {
      this.$swal
        .mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        })
        .fire({
          icon: status,
          title: messages,
        });
    },
    editItem(item) {
      this.$router.push(
        `/${this.$i18n.locale}/company/employee/master/edit/${item.id}`
      );
    },
    deleteItem(item) {
      this.editedIndex = item;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
        console.log('ewuhrowjeoirjwoiejr', this.editedIndex)
      let vm = this;
      try {
        let form = { person_id: this.editedIndex.person_id };
        (async function () {
          const res = await API.del(
            `${process.env.VUE_APP_API_TRUST}employee/delete`,
            {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Auth.getToken()}`,
            },
            [form]
          );

          if (res.statusCode === 200) {
            window.location.href = `/company/employee/master`;
            vm.toast("success", vm.$t("success_insert_data"));
            setTimeout(function () {
              vm.getDataEmployee();
            }, 200);
          } else if (res.statusCode == 403) {
            vm.toast("error", res.message);
            // Object.keys(res.errors).forEach(element => {
            //   vm.toast(
            //     "error",
            //     typeof res.errors[element] == "object"
            //       ? res.errors[element][0]
            //       : res.errors[element]
            //   );
            // });
          } else {
            vm.$swal({
              icon: "error",
              text: vm.$t("error_try_again_latter"),
            });
          }
        })();
      } catch (error) {
        // console.log(error);
        this.$swal({
          icon: "error",
          text: vm.$t("error_try_again_latter"),
        });
      }
      this.closeDelete();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async getInitialEmployee(getDatas) {
      try {
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}employee/get?id=${getDatas}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );

        // Check result exist
        if (res.results) {
          this.employeeDetailData = res.results[0];
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getInitialRevenuePopup(getDatas) {
      this.idTemp = getDatas;
      try {
        this.myloadingvariablePopup = true;
        // pagination showing start of entries
        let startPagePopup = (this.pagePopup - 1) * this.perPagePopup;
        let showPagePopup = this.perPagePopup;
        this.itemShowStartPopup = startPagePopup + 1;
        this.itemShowStopPopup = startPagePopup + showPagePopup;

        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}employee/get-history?query=${this.searchPopup}&employee_id=${getDatas}&offset=${startPagePopup}&per_page=${showPagePopup}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );

        // Check result exist
        if (res.results) {
          this.employeeList = res.results;

          this.itemsTotalPopup = res.total;
          this.itemLengthPopup = Math.ceil(
            this.itemsTotalPopup / showPagePopup
          );
          if (this.itemShowStopPopup < 0) {
            this.itemShowStopPopup = res.total;
          }
          if (this.itemShowStopPopup > res.total) {
            this.itemShowStopPopup = res.total;
          }
          this.myloadingvariablePopup = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    toggleDialogOrder(toggle) {
      this.dialog_order = toggle;
    },
    async openDialogOrder(item) {
      this.dataDialogOrder.order_number = item.order_number;
      this.dataDialogOrder.type_test = item.type;
      this.getOrderDetailPopup();
    },
    async getOrderDetailPopup() {
      try {
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}medical-record/inspection/list?order_number=${this.dataDialogOrder.order_number}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        if (res.statusCode === 200) {
          this.dataDialogOrder.results = res.results;
          this.dialog_order = true;
        } else if (res.statusCode == 400) {
          this.$swal({
            icon: "error",
            text: res.message,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
@media screen and (min-width: 1023px) {
  .sticky-c1 {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;

    background: #fff;
  }
  .sticky-c2 {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 155px;

    background: #fff;
  }
  .sticky-h1 {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    z-index: 5;
    background: #ddd;
  }
  .sticky-h2 {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 155px;
    z-index: 5;
    background: #ddd;
  }
}
.table-master-employee thead {
  background: #ddd !important;
  font-weight: bold;
  color: #000 !important;
}
.table-master-employee thead tr th {
  height: 38px !important;
  white-space: nowrap;
}
.table-master-employee tbody tr td {
  font-size: 12px !important;
  height: 35px !important;
  white-space: nowrap;
}
.table-master-employee tbody tr:hover {
  background-color: #eee !important;
}
</style>
<style lang="scss" scoped>
@import "@/assets/css/custom.scss";
.home {
  display: flex;
  flex-direction: column;
  padding: 1px 0 40px 0;
  &__section {
    padding: 0 20px;
  }
}
@media screen and (min-width: 1023px) {
  .hd-sticky:hover {
    .sticky-c1 {
      background: #eee !important;
    }
    .sticky-c2 {
      background: #eee !important;
    }
    .v-data-table
      > .v-data-table__wrapper
      tbody
      tr:first-child:hover
      td:first-child {
      background: #eee !important;
    }
  }
}
.searchField {
  max-width: 500px;
}
.button--active {
  background: $main_3;
  color: white;
}
.button--outline {
  color: $main_3;
  border: 1px solid $main_3;
  background: white;
}
.button--download {
  background: #79c4c6;
  color: white;
}
.button-box {
  box-sizing: border-box;
  border-radius: 10px;
  padding: 8px 16px;
  outline: none;
}
.card--result {
  background: $gray_3;
  padding: 18px;
  border-radius: 16px;
  width: 100%;
  // max-width: 300px;
  color: $gray_2;
  h4 {
    color: $gray_1;
    font-size: 20px;
  }
  p {
    margin: 0;
  }
  .card--result__item {
    padding: 6px 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $gray_2;
    &:nth-last-child(1) {
      border: none;
    }
  }
}

.status--inprogress {
  color: $yellow_1;
  font-weight: 600;
}
.status--complete {
  color: $green_1;
  font-weight: 600;
}
.status--cancel {
  color: $red_1;
  font-weight: 600;
}
.tabs {
  background: #e5e5e5 !important;
}
.content-table {
  padding: 15px;
  @media screen and (max-width: 960px) {
    padding: 5px 18px 18px 18px;
  }
}
@media screen and (max-width: 480px) {
  .content-table {
    padding: 0px !important;
  }
}
.fontSet {
  font-size: 14px !important;
}
.button {
  background-color: #00a4b3 !important;
  border-radius: 5px;
  color: white !important;
  font-size: 14px;
  text-align: center;
  text-transform: capitalize;
  height: 30px !important;
  min-width: 100px !important;
}
a {
  text-decoration: none;
}
.fontSet {
  font-size: 14px !important;
}
.box-page-new {
  margin: 20px;
  border-radius: 4px !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 12px;
  vertical-align: baseline;
  padding-top: 6px;
  padding-bottom: 6px;
  height: 25px !important;
}

.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  height: 35px !important;
}
.button-edit-employee {
  background-color: #00a4b3 !important;
  color: white;
  margin-right: 7px;
}
.button-delete-employee {
  background-color: #eefdfe !important;
  color: #00a4b3;
  margin-left: 7px;
}
.title-detail {
  color: #1a428a;
}
.text-title {
  font-size: 12px;
  text-transform: uppercase;
  color: #00a4b3;
  font-weight: bold;
}
.text-new-title {
  font-size: 12px;
  text-transform: uppercase;
  color: #000;
  font-weight: bold;
}
.text-new-value {
  font-size: 12px;
  text-transform: uppercase;
  color: #000;
}
.text-value {
  font-size: 12px;
  text-transform: uppercase;
  color: #000;
  text-align: right;
}
.title-information {
  font-size: 16px;
}
.list-data {
  padding: 12px 8px 0 8px !important;
}
</style>
<style>
.table-employee-company thead {
  background: #ddd !important;
  font-weight: bold;
  color: #000 !important;
}
.table-employee-company thead tr th {
  height: 38px !important;
  white-space: nowrap;
}
.table-employee-company tbody tr td {
  font-size: 12px !important;
  height: 35px !important;
  white-space: nowrap;
}
.table-employee-company tbody tr:hover {
  background-color: #eee !important;
}
.table-lists {
  padding: 4px 16px 4px 16px;
}
</style>
<style lang="scss">
.footerHide {
  background: none !important;
  align-items: center;
  width: 50px;
  font-size: 12px;
}
.footerHide > .v-data-footer__pagination {
  display: none !important;
}
.footerHide > .v-data-footer__icons-before {
  display: none !important;
}
.footerHide > .v-data-footer__icons-after {
  display: none !important;
}
.payment-info .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 12px;
  vertical-align: baseline;
  padding-top: 6px;
  padding-bottom: 6px;
}
.v-toolbar__title {
  font-size: 16px;
}
.footer-payment {
  margin: 0 auto !important;
}
.footer-showing {
  font-size: 12px !important;
  margin: 0 auto !important;
}

.footerHide > .v-text-field .v-input__prepend-inner,
.v-text-field .v-input__append-inner {
  padding-left: 0 !important;
}
</style>
<style>
/* Hide scrollbar for Chrome, Safari and Opera */
.table-stick-master-employee .v-data-table__wrapper::-webkit-scrollbar {
  height: 8px;
  width: 0px;
  border: 1px solid #fff;
}

.table-stick-master-employee .v-data-table__wrapper::-webkit-scrollbar-track {
  border-radius: 0;
  background: #eeeeee;
}

.table-stick-master-employee .v-data-table__wrapper::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: #b0b0b0;
}

/* Hide scrollbar for IE, Edge and Firefox */
.table-stick-master-employee .v-data-table__wrapper {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.super-dense > .v-data-table__wrapper > table > tbody > tr > td,
.super-dense > .v-data-table__wrapper > table > tbody > tr > th,
.super-dense > .v-data-table__wrapper > table > thead > tr > td,
.super-dense > .v-data-table__wrapper > table > thead > tr > th,
.super-dense > .v-data-table__wrapper > table > tfoot > tr > td {
  height: 35px !important;
}
.table-stick-master-employee .v-data-table__wrapper {
  overflow-x: auto;
  overflow-y: auto;
  height: 400px !important;
}
.table-stick-master-employee thead {
  position: sticky;
  inset-block-start: 0;
  top: 0;
  background: #ddd !important;
  font-weight: bold;
  color: #000 !important;
  z-index: 5;
}
</style>
